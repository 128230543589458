import React, { useState } from 'react';

const RenwaForm = () => {
  const [formData, setFormData] = useState({
    licenseNumber: '',
    userIDPassword: '',
    state: '',
    pincode: '',
    categories: [],
    businessActivity: '',
    licenseValidity: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter(item => item !== value)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      licenseNumber,
      userIDPassword,
      state,
      pincode,
      categories,
      businessActivity,
      licenseValidity
    } = formData;

    // Construct the WhatsApp message
    const whatsappMessage = `License Renewal \n Existing FSSAI License Number: ${licenseNumber}\n Do you have the UserID and Password of your FSSAI Account?: ${userIDPassword}\n State: ${state}\n Pincode: ${pincode}\n Food Product Categories: ${categories.join(', ')}\n Business Activity: ${businessActivity}\n License Validity: ${licenseValidity}`;

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919309378203&text=${encodedMessage}`;

    // Redirect to WhatsApp
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
    <section className="about-us-hero">
    <div className="hero-content">
      <h1>License Registration Form</h1>
    </div>
  </section>
    <div className="fassai-registration-page">
      <h2>License Renewal Form</h2>
      <form className="fassai-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Existing FSSAI License Number</label>
          <input
            type="text"
            name="licenseNumber"
            placeholder="Existing FSSAI License Number"
            required
            value={formData.licenseNumber}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Do you have the UserID and Password of your FSSAI Account?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="userIDPassword"
                value="Yes"
                checked={formData.userIDPassword === 'Yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="userIDPassword"
                value="No"
                checked={formData.userIDPassword === 'No'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>

        <div className="form-group-inline">
          <div className="form-group">
            <label>* Select Your State</label>
            <select
              name="state"
              required
              value={formData.state}
              onChange={handleInputChange}
            >
              <option value="">Select Your State</option>
              {/* Add all state options here */}
              <option value="AP">Andhra Pradesh</option>
<option value="Arunachal Pradesh">Arunachal Pradesh</option>
<option value="Assam">Assam</option>
<option value="Bihar">Bihar</option>
<option value="Chhattisgarh">Chhattisgarh</option>
<option value="Goa">Goa</option>
<option value="Gujarat">Gujarat</option>
<option value="Haryana">Haryana</option>
<option value="Himachal Pradesh">Himachal Pradesh</option>
<option value="Jharkhand">Jharkhand</option>
<option value="Karnataka">Karnataka</option>
<option value="Kerala">Kerala</option>
<option value="Madhya Pradesh">Madhya Pradesh</option>
<option value="Maharashtra">Maharashtra</option>
<option value="Manipur">Manipur</option>
<option value="Meghalaya">Meghalaya</option>
<option value="Mizoram">Mizoram</option>
<option value="Nagaland">Nagaland</option>
<option value="Odisha">Odisha</option>
<option value="Punjab">Punjab</option>
<option value="Rajasthan">Rajasthan</option>
<option value="Sikkim">Sikkim</option>
<option value="Tamil Nadu">Tamil Nadu</option>
<option value="Telangana">Telangana</option>
<option value="Tripura">Tripura</option>
<option value="Uttar Pradesh">Uttar Pradesh</option>
<option value="Uttarakhand">Uttarakhand</option>
<option value="West Benga">West Bengal</option> 
              {/* ... other options */}
            </select>
          </div>

          <div className="form-group">
            <label>* Pincode</label>
            <input
              type="text"
              name="pincode"
              placeholder="Enter Pincode"
              required
              value={formData.pincode}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label>* Food Product Category</label>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="categories"
                value="Dairy Product"
                checked={formData.categories.includes('Dairy Product')}
                onChange={handleInputChange}
              />
              Dairy Product
            </label>
            <label>
              <input
                type="checkbox"
                name="categories"
                value="Fats & oils"
                checked={formData.categories.includes('Fats & oils')}
                onChange={handleInputChange}
              />
              Fats & oils
            </label>
            <label>
              <input type="checkbox" name="categories" value="Edibles ices, including ice creams"
              checked={formData.categories.includes('Edibles ices, including ice creams')}
              onChange={handleInputChange}  />
              Edibles ices, including ice creams
            </label>
            <label>
            <input type="checkbox" name="categories" value="Fruits & vegetables, nuts and seeds"
           checked={formData.categories.includes('Fruits & vegetables, nuts and seeds')}
           onChange={handleInputChange}/> Fruits & vegetables, nuts and seeds
            </label>
            <label>
              <input type="checkbox" name="categories" value="Confectionery" 
              checked={formData.categories.includes('Confectionery')}
              onChange={handleInputChange} />
              Confectionery
            </label>
            <label>
              <input type="checkbox" name="categories" value="Cereals and cereal products & pulses"
                checked={formData.categories.includes('Cereals and cereal products & pulses')}
                onChange={handleInputChange}  />
              Cereals and cereal products & pulses
            </label>
            <label>
              <input type="checkbox" name="categories" value="Bakery products" 
               checked={formData.categories.includes('Bakery products')}
               onChange={handleInputChange} />
              Bakery products
            </label>
            <label>
              <input type="checkbox" name="categories" value="Meat and meat products, including poultry"
               checked={formData.categories.includes('Meat and meat products, including poultry')}
               onChange={handleInputChange} />
              Meat and meat products, including poultry
            </label>
            <label>
              <input type="checkbox" name="categories" value="Fish and fish products"
               checked={formData.categories.includes('fish and fish products')}
               onChange={handleInputChange}  />
              Fish and fish products
            </label>
            <label>
              <input type="checkbox" name="categories" value="Eggs and egg products" 
             checked={formData.categories.includes('Eggs and egg products')}
             onChange={handleInputChange}/>
              Eggs and egg products
            </label>
            <label>
              <input type="checkbox" name="categories" value="Sweeteners, including honey" 
                checked={formData.categories.includes('Sweeteners, including honey')}
                onChange={handleInputChange}/>
              Sweeteners, including honey
            </label>
            <label>
              <input type="checkbox" name="categories" value="Salts, spices, soups, sauces, salads and protein products"
               checked={formData.categories.includes('Salts, spices, soups, sauces, salads and protein products')}
               onChange={handleInputChange}  />
              Salts, spices, soups, sauces, salads and protein products
            </label>
            <label>
              <input type="checkbox" name="categories" value="Foodstuffs intended for particular nutritional uses" 
                  checked={formData.categories.includes('Foodstuffs intended for particular nutritional uses')}
                  onChange={handleInputChange} />
              Foodstuffs intended for particular nutritional uses
            </label>
            <label>
              <input type="checkbox" name="categories" value="Beverages, excluding dairy products" 
                  checked={formData.categories.includes('Beverages, excluding dairy products')}
                  onChange={handleInputChange} />
              Beverages, excluding dairy products
            </label>
            <label>
              <input type="checkbox" name="categories" value="Ready to eat savouries" 
              checked={formData.categories.includes('Ready to eat savouries')}
              onChange={handleInputChange} />
              Ready to eat savouries
            </label>
            <label>
              <input type="checkbox" name="categories" value="Prepared foods" 
              checked={formData.categories.includes('Prepared foods')}
              onChange={handleInputChange} />
              Prepared foods
            </label>
            <label>
              <input type="checkbox" name="category" value="Substances added to food"
              checked={formData.categories.includes('Prepared foods')}
              onChange={handleInputChange}  />
              Substances added to food
            </label>
            <label>
              <input type="checkbox" name="category" value="Other (not covered under above categories)" 
             checked={formData.categories.includes('Other (not covered under above categories)')}
             onChange={handleInputChange}  />
              Other (not covered under above categories)
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>Please select your business activity</label>
          <select
            name="businessActivity"
            required
            value={formData.businessActivity}
            onChange={handleInputChange}
          >
            <option value="">Select Your Business Activity</option>
            <option value="Dhaba">Dhaba</option>
            <option value="Canteen/Mess">Canteen/Mess</option>
            <option value="Food Stall">Food Stall</option>
            <option value="Hawker">Hawker</option>
            <option value="Restaurant">Restaurant</option>
            <option value="Hotel">Hotel</option>
            <option value="Supplier /Wholesaler /Retailer /Marketer">Supplier /Wholesaler /Retailer /Marketer</option>
            <option value="Food Importer/Exporter">Food Importer/Exporter</option>
            <option value="Food processing (re-label/re-packing)">Food processing (re-label/re-packing)</option>
            <option value="Vegetable oil">Vegetable oil</option>
            <option value="Meat processing">Meat processing</option>
            <option value="Storage">Storage</option>
            <option value="Caterer">Caterer</option>
          </select>
        </div>

        <div className="form-group">
          <label>Do you want to increase the validity of the license?</label>
          <select
            name="licenseValidity"
            required
            value={formData.licenseValidity}
            onChange={handleInputChange}
          >
            <option value="">Select Validity (Years)</option>
            <option value="1">1 Year</option>
            <option value="2">2 Years</option>
            <option value="3">3 Years</option>
            <option value="4">4 Years</option>
          </select>
        </div>

        <div className="form-actions">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
    </div>
    </>
  );
}

export default RenwaForm;
