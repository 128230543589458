import React, { useState } from 'react';
import './FassaiBasic.css';
import fssai from './images/CR-Health-Inlinehero-fast-way-to-make-food-healthier-0219.webp'
import { Link } from 'react-router-dom';

const FassaiState = () => {
  const [business, setBusiness] = useState('');
  const [nominee, setNominee] = useState('');
  const [businessEntity, setBusinessEntity] = useState('');
  const[ address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [businessActivity, setActivity] = useState('');
  const [turnover, setTurnover] = useState('');
  const [Validity, setValidity] = useState('');

  const handleBusinessEntityChange = (e) => {
    setBusinessEntity(e.target.value);
  };

const handleCategoryChange = (e) => {
  const value = e.target.value;
  setSelectedCategories(prevSelectedCategories =>
    prevSelectedCategories.includes(value)
      ? prevSelectedCategories.filter(category => category !== value)
      : [...prevSelectedCategories, value]
  );
};
const handleSubmit = (e) => {
  e.preventDefault();

  // Construct the WhatsApp message with line breaks
  const whatsappMessage = `
  For FASSAI State License
    Business Name: ${business}\n
    Nominee: ${nominee}\n
    Business Entity: ${businessEntity}\n
    Address: ${address}\n
    State: ${state}\n
    Pincode: ${pincode}\n
    Selected Categories: ${selectedCategories.join(', ')}\n
    Business Activity: ${businessActivity}\n
    Expected Turnover: ${turnover}\n
    License Validity: ${Validity}
  `;

  // Encode the message for URL
  const encodedMessage = encodeURIComponent(whatsappMessage);
  const whatsappUrl = `https://api.whatsapp.com/send?phone=919309378203&text=${encodedMessage}`;

  // Redirect to WhatsApp
  window.open(whatsappUrl, '_blank');
};


  return (
    <div className="fassai-basic-page">
      <header className="hero-section-document">
        <div className="hero-content">
          <h1> FSSAI State License Registration in India</h1>
          <p>The Food Standards and Safety Authority of India (FSSAI) is the supreme authority which is responsible for regulating and supervising the food safety.</p>
          <Link to='/renewform' className="apply-button">APPLY FOR LICENSE</Link>
        </div>
      </header>

      <div className="content-section">
      <div className="fassai-registration-page">
      <h2>License Registration Form</h2>
      <form className="fassai-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name of Business/Company</label>
          <input
            type="text"
            placeholder="Mention the name of the business entity (ex. company/firm name)"
            value={business}
            onChange={(e) => setBusiness(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>* Name of the Nominee</label>
          <input
            type="text"
            placeholder="Mention the name of the person responsible for taking the necessary steps on behalf of business."
            value={nominee}
            onChange={(e) => setNominee(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>* Choose Your Business Entity</label>
          <div className="radio-group">
            <label>
              <input type="radio"
               name="businessEntity" 
               value="Private Limited Company" 
               checked={businessEntity === "Private Limited Company"} 
               onChange={handleBusinessEntityChange} />
              Private Limited Company
            </label>
            <label>
              <input type="radio" name="businessEntity" value="Public Limited Company" 
               checked={businessEntity === "Public Limited Company"} 
               onChange={handleBusinessEntityChange}/>
              Public Limited Company
            </label>
            <label>
              <input type="radio" name="businessEntity" value="Partnership"
                checked={businessEntity === "Partnership"} 
                onChange={handleBusinessEntityChange}
                 />
              Partnership
            </label>
            <label>
              <input type="radio" name="businessEntity" value="Proprietorship"
                checked={businessEntity === "Proprietorship"} 
                onChange={handleBusinessEntityChange} />
              Proprietorship
            </label>
            <label>
              <input type="radio" name="businessEntity" value="Other"
                checked={businessEntity === "Other"} 
                onChange={handleBusinessEntityChange} />
              Other
            </label>
          </div>
        </div>

        <div className="form-group">
          <label>* Address of Business/Unit</label>
          <textarea
            placeholder="Mention the address of business where food activity is carried out"
            rows="3"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          ></textarea>
        </div>

        <div className="form-group-inline">
          <div className="form-group">
            <label>* Select Your State</label>
            <select required value={state}  onChange={(e) => setState(e.target.value)}>
              <option value="">Select Your State</option>
              <option value="AP">Andhra Pradesh</option>
<option value="Arunachal Pradesh">Arunachal Pradesh</option>
<option value="Assam">Assam</option>
<option value="Bihar">Bihar</option>
<option value="Chhattisgarh">Chhattisgarh</option>
<option value="Goa">Goa</option>
<option value="Gujarat">Gujarat</option>
<option value="Haryana">Haryana</option>
<option value="Himachal Pradesh">Himachal Pradesh</option>
<option value="Jharkhand">Jharkhand</option>
<option value="Karnataka">Karnataka</option>
<option value="Kerala">Kerala</option>
<option value="Madhya Pradesh">Madhya Pradesh</option>
<option value="Maharashtra">Maharashtra</option>
<option value="Manipur">Manipur</option>
<option value="Meghalaya">Meghalaya</option>
<option value="Mizoram">Mizoram</option>
<option value="Nagaland">Nagaland</option>
<option value="Odisha">Odisha</option>
<option value="Punjab">Punjab</option>
<option value="Rajasthan">Rajasthan</option>
<option value="Sikkim">Sikkim</option>
<option value="Tamil Nadu">Tamil Nadu</option>
<option value="Telangana">Telangana</option>
<option value="Tripura">Tripura</option>
<option value="Uttar Pradesh">Uttar Pradesh</option>
<option value="Uttarakhand">Uttarakhand</option>
<option value="West Benga">West Bengal</option> 
            </select>
          </div>

          <div className="form-group">
            <label>* Pincode</label>
            <input type="text" placeholder="Enter Pincode"
             value={pincode}
             onChange={(e) => setPincode(e.target.value)}
             required />
          </div>
        </div>

        <div className="form-group">
          <label>* Food Product Category</label>
          <div className="checkbox-group">
            <label>
              <input type="checkbox" name="category" value="Dairy Product" 
               checked={selectedCategories.includes("Dairy Product")} 
               onChange={handleCategoryChange} />
              Dairy Product
            </label>
            <label>
              <input type="checkbox" name="category" value="Fats & oils"
               checked={selectedCategories.includes("Fats & oils")} 
               onChange={handleCategoryChange}  />
              Fats & oils
            </label>
            <label>
              <input type="checkbox" name="category" value="Edibles ices, including ice creams"
               checked={selectedCategories.includes("Edibles ices, including ice creams")} 
               onChange={handleCategoryChange}  />
              Edibles ices, including ice creams
            </label>
            <label>
            <input type="checkbox" name="category" value="Fruits & vegetables, nuts and seeds"
             checked={selectedCategories.includes("Fruits & vegetables, nuts and seeds")} 
             onChange={handleCategoryChange}  /> Fruits & vegetables, nuts and seeds
            </label>
            <label>
              <input type="checkbox" name="category" value="Confectionery" 
               checked={selectedCategories.includes("Confectionery")} 
               onChange={handleCategoryChange} />
              Confectionery
            </label>
            <label>
              <input type="checkbox" name="category" value="Cereals and cereal products & pulses"
               checked={selectedCategories.includes("Cereals and cereal products & pulses")} 
               onChange={handleCategoryChange}  />
              Cereals and cereal products & pulses
            </label>
            <label>
              <input type="checkbox" name="category" value="Bakery products" 
               checked={selectedCategories.includes("Bakery products")} 
               onChange={handleCategoryChange} />
              Bakery products
            </label>
            <label>
              <input type="checkbox" name="category" value="Meat and meat products, including poultry"
               checked={selectedCategories.includes("Meat and meat products, including poultry")} 
               onChange={handleCategoryChange}  />
              Meat and meat products, including poultry
            </label>
            <label>
              <input type="checkbox" name="category" value="Fish and fish products"
               checked={selectedCategories.includes("Fish and fish products")} 
               onChange={handleCategoryChange}  />
              Fish and fish products
            </label>
            <label>
              <input type="checkbox" name="category" value="Eggs and egg products" 
               checked={selectedCategories.includes("Eggs and egg products")} 
               onChange={handleCategoryChange} />
              Eggs and egg products
            </label>
            <label>
              <input type="checkbox" name="category" value="Sweeteners, including honey" 
               checked={selectedCategories.includes("Sweeteners, including honey")} 
               onChange={handleCategoryChange} />
              Sweeteners, including honey
            </label>
            <label>
              <input type="checkbox" name="category" value="Salts, spices, soups, sauces, salads and protein products"
               checked={selectedCategories.includes("Salts, spices, soups, sauces, salads and protein products")} 
               onChange={handleCategoryChange}  />
              Salts, spices, soups, sauces, salads and protein products
            </label>
            <label>
              <input type="checkbox" name="category" value="Foodstuffs intended for particular nutritional uses" 
               checked={selectedCategories.includes("Foodstuffs intended for particular nutritional uses")} 
               onChange={handleCategoryChange} />
              Foodstuffs intended for particular nutritional uses
            </label>
            <label>
              <input type="checkbox" name="category" value="Beverages, excluding dairy products" 
               checked={selectedCategories.includes("Beverages, excluding dairy products")} 
               onChange={handleCategoryChange} />
              Beverages, excluding dairy products
            </label>
            <label>
              <input type="checkbox" name="category" value="Ready to eat savouries" 
               checked={selectedCategories.includes("Ready to eat savouries")} 
               onChange={handleCategoryChange} />
              Ready to eat savouries
            </label>
            <label>
              <input type="checkbox" name="category" value="Prepared foods" 
               checked={selectedCategories.includes("Prepared foods")} 
               onChange={handleCategoryChange} />
              Prepared foods
            </label>
            <label>
              <input type="checkbox" name="category" value="Substances added to food"
               checked={selectedCategories.includes("Substances added to food")} 
               onChange={handleCategoryChange}  />
              Substances added to food
            </label>
            <label>
              <input type="checkbox" name="category" value="Other (not covered under above categories)" 
               checked={selectedCategories.includes("Other (not covered under above categories)")} 
               onChange={handleCategoryChange} />
              Other (not covered under above categories)
            </label>

          </div>
        </div>

        <div className="form-group">
          <label>* Please select your business activity</label>
          <select name="businessActivity" value={businessActivity}  onChange={(e) => setActivity(e.target.value)} required>
            <option value="">Select Your Business Activity</option>
            <option value="Dhaba">Dhaba</option>
            <option value="Canteen/Mess">Canteen/Mess</option>
            <option value="Food Stall">Food Stall</option>
            <option value="Hawker">Hawker</option>
            <option value="Restaurant">Restaurant</option>
            <option value="Hotel">Hotel</option>
            <option value="Supplier /Wholesaler /Retailer /Marketer">Supplier /Wholesaler /Retailer /Marketer</option>
            <option value="Food Importer/Exporter">Food Importer/Exporter</option>
            <option value="Food processing (re-label/re-packing)">Food processing (re-label/re-packing)</option>
            <option value="Vegetable oil">Vegetable oil</option>
            <option value="Meat processing">Meat processing</option>
            <option value="Storage">Storage</option>
            <option value="Caterer">Caterer</option>
          </select>
        </div>

        <div className="form-group">
          <label>Expected Turnover</label>
          <input
            type="text"
            placeholder="Mention expected turnover of your business as per year"
            value={turnover}  
            onChange={(e) => setTurnover(e.target.value)}
            required
          />
        </div>
             
        <div className="form-group">
          <label>* Do you want to increase the validity of the license?</label>
          <select name="licenseValidity" value={Validity}  onChange={(e) => setValidity(e.target.value)} required>
            <option value="">Select Validity (Years)</option>
            <option value="1 Year">1 Year</option>
            <option value="2 Year">2 Years</option>
            <option value="3 Year">3 Years</option>
            <option value="4 Year">4 Years</option>
          </select>
        </div>
        
        <div className="form-actions">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
    </div>

        <div className="license-info">
          <h2>Documents for FSSAI Form A State License Registration</h2>
          <img src={fssai} alt="FSSAI Registration" className="info-image" />
          <p>The Food Standards and Safety Authority of India (FSSAI) is the supreme authority which is responsible for regulating and supervising the food safety. So it is mandatory to take FSSAI Food safety License Registration as per the law. Here we discuss about FSSAI state license. Businesses having annual turnover between Rs.12 lakhs to 20 crore can apply for FSSAI state license. Food business operators like small to medium-sized manufacturers, storage units, Transporters, Retailers, Restaurants Marketers, distributors etc. are however required to obtain the FSSAI State License Registration.
          FSSAI License is mandatory for anyone who intends to do food business. This not only involves preparing food but everyone who handles food at various stages before it ultimately reaches the customer like raw materials, manufacturing, processing, Restaurants packing and the distribution as well as the agencies who have authority to sell them.</p>
          <p style={{fontWeight:'bold'}}>DOCUMENTS REQUIRED FOR FSSAI BASIC REGISTRATION</p>
          <ul>
            <li>Rental Agreement of Business Premises.</li>
            <li>ID Proof of the Concerned Person (Aadhaar Card / Driving License / Passport / Voter ID)</li>
            <li>If any Government Registration Certificates ( Company Incorporation Certificate / Firm Registration / Partnership Deed / Pan card / GST Registration Number / Shop and Establishment Registration / Trade License)</li>
            <li>If the applicant is private limited company or partnership firm then they should provide MOA & AOA or Partnership deed copy</li>
            <li>For applying State License  any One of the following certificate is compulsory  ( Trade license, Shop & Establishment, Panchayath License, Corporation License , Municipality License )</li>
            <li>Nature of Business.</li>
            <li>Fssai declaration form</li>
          </ul>
          <p style={{fontWeight:'bold'}}>If you are applying for Manufacturing /Repacker category please arrange below other documents.</p>
          <ul>
            <li>Manufacturing unit photos</li>
            <li> Plant Layouts.</li>
            <li> Machinery details use for production in your company letterhead (Capacity & Horse Power details)</li>
            <li>Product details in company Letter head.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FassaiState;
