import React, { useState } from 'react';
import './ContactUs.css';
import headerImage from './images/istockphoto-884374384-170667a.jpg'; // Make sure to place your image in the assets folder

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    address: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, mobile, address, email, message } = formData;

    // Construct the WhatsApp message
    const whatsappMessage = `Name: ${name}\nMobile Number: ${mobile}\nAddress: ${address}\nEmail: ${email}\nMessage: ${message}`;

    // Encode the message for URL
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919309378203&text=${encodedMessage}`;

    // Redirect to WhatsApp
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="contact-us">
      <header className="contact-header">
        <img src={headerImage} alt="Contact Us" className="header-image" />
        <h1 className="header-text">Contact Us</h1>
      </header>
      <div className="contact-content">
        <section className="contact-form">
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
            />

            <label htmlFor="mobile">Mobile Number:</label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              required
              value={formData.mobile}
              onChange={handleInputChange}
            />

            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              name="address"
              required
              value={formData.address}
              onChange={handleInputChange}
            />
            
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
            
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              required
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            
            <button type="submit">Send Message</button>
          </form>
        </section>
        <section className="contact-details">
          <p className='contact-head'>Contact Details</p>
          <p className='chead'>We're Here to Help!</p>
          <p>Whether you have questions about food licensing, need assistance with the application process, or are looking for more information on compliance, our team is ready to assist you.</p>
          <p className='chead'>Visit Our Office</p>
          <p></p>
          <p> Address: Office no G28, Second floor, Mansing market , above white House , Golani Market, Jalgaon 425001</p>
          <p>Email: info@foodlicenseconsultancy.com</p>
          <p>Phone: +91-12345-67890</p>
          
        </section>
      </div>
      <section className="fssai-info">
        <h2 className="section-title">Who Needs FSSAI Food Safety License</h2>
        <p className="section-description">
          The primary step for FSSAI Food safety Registration and Licensing is identifying the nature of business structure.
          There are various kinds of businesses like Manufacturer, Repacker, Retailer, Distributor, Supplier, Marketer,
          Importer, Exporter, E-commerce, Wholesaler, Restaurant, Canteen, Mess, Food vending establishment, Food vending
          agencies, Petty Retailer for Tea and Snacks.
        </p>
      </section>
    </div>
  );
};

export default ContactUs;
